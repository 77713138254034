/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'

/**
 * Contact form component.
 */
class ContactForm extends Component {
  render () {
    return (
      <div className={`contact-form`}>
        <form
          action={`//formspree.io/mifsud.k@gmail.com`}
          method={`POST`}
        >
          <div className="form-group">
            <input
              required={`required`}
              type="text"
              className="form-control"
              id="fullname"
              name={`FullName`}
              placeholder={`Your full name (required)`}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="businessName"
              name={`Business`}
              placeholder={`Your business name`}
            />
          </div>
          <div className="form-group">
              <input
                required={`required`}
                type="email"
                className="form-control"
                id="email"
                name={`Email`}
                placeholder={`Your email address (required)`}
              />
          </div>
           <div className="form-group">
              <textarea
                required={`required`}
                name="Message"
                id="message"
                placeholder={`How can I help you? (required)`}
                className={`form-control`}
              ></textarea>
           </div>
           <div className="form-group">
              <button className="btn btn-warning btn-block">
                Send
              </button>
           </div>
        </form>
      </div>
    )
  }
}

export default ContactForm
